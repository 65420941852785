<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div v-if="!getMenu">
        <div class="mx-auto sm:max-w-xl md:max-w-full xl:max-w-screen-2xl p-2">
          <div class="text-gray-800 mt-10">
            <a
              v-on:click="$router.go(-1)"
              class="flex items-center space-x-2 text-center cursor-pointer"
              ><i class="fad fa-arrow-left mr-1"></i><span>Go Back</span></a
            >
          </div>

          <div class="mt-16">
            <div
              class="grid gap-4 grid-cols-1 lg:grid-cols-2 md:grid-cols-1 mt-10"
            >
              <div>
                <h3 class="text-3xl font-sans font-medium">
                  Top Up Account Balance with Card
                </h3>

                <h3 class="text-md text-gray-600 mt-5">
                  Craft masters uses secure, encryped technology to store and
                  handle your credit card information. Rest assured, the
                  confidential data you enter here is safe. Where possible,
                  we've pre-populated the entry fields on this page with your
                  previously stored information.
                </h3>

                <h3 class="text-2xl font-sans font-medium mt-10">
                  Card Details
                </h3>

                <div
                  class="flex justify-center py-1 sm:py-3 px-3 rounded border-2 mt-5"
                >
                  <input
                    v-model="negative_prompt"
                    placeholder="Name on Card"
                    class="flex-grow outline-none text-lg bg-transparent text-gray-700"
                    type="String"
                  />
                  <b class="text-gray-600"> Name on Card </b>
                </div>

                <div
                  class="flex justify-center py-1 sm:py-3 px-3 rounded border-2 mt-5"
                >
                  <input
                    v-model="negative_prompt"
                    placeholder="Name on Card"
                    class="flex-grow outline-none text-lg bg-transparent text-gray-700"
                    type="String"
                  />
                  <b class="text-gray-600"> Card number </b>
                </div>

                <div class="grid gap-3 grid-cols-3">
                  <div
                    class="flex justify-center py-1 sm:py-3 px-3 rounded border-2 mt-5"
                  >
                    <input
                      v-model="negative_prompt"
                      placeholder="MM"
                      class="flex-grow outline-none text-lg bg-transparent text-gray-700"
                      type="String"
                    />
                  </div>
                  <div
                    class="flex justify-center py-1 sm:py-3 px-3 rounded border-2 mt-5"
                  >
                    <input
                      v-model="negative_prompt"
                      placeholder="YY"
                      class="flex-grow outline-none text-lg bg-transparent text-gray-700"
                      type="String"
                    />
                  </div>
                  <div
                    class="flex justify-center py-1 sm:py-3 px-3 rounded border-2 mt-5"
                  >
                    <input
                      v-model="negative_prompt"
                      placeholder="CVC"
                      class="flex-grow outline-none text-lg bg-transparent text-gray-700"
                      type="String"
                    />
                  </div>
                </div>

                <h3 class="text-2xl font-sans font-medium mt-16">
                  Billing Address
                </h3>

                <div
                  class="flex justify-center py-1 sm:py-3 px-3 rounded border-2 mt-5"
                >
                  <input
                    v-model="negative_prompt"
                    placeholder="First Name"
                    class="flex-grow outline-none text-lg bg-transparent text-gray-700"
                    type="String"
                  />
                  <b class="text-gray-600">First Name</b>
                </div>

                <div
                  class="flex justify-center py-1 sm:py-3 px-3 rounded border-2 mt-5"
                >
                  <input
                    v-model="negative_prompt"
                    placeholder="Last Name"
                    class="flex-grow outline-none text-lg bg-transparent text-gray-700"
                    type="String"
                  />
                  <b class="text-gray-600">Last Name</b>
                </div>

                <div
                  class="flex justify-center py-1 sm:py-3 px-3 rounded border-2 mt-5"
                >
                  <input
                    v-model="negative_prompt"
                    placeholder="Address Line 1"
                    class="flex-grow outline-none text-lg bg-transparent text-gray-700"
                    type="String"
                  />
                  <b class="text-gray-600">Address Line 1</b>
                </div>

                <div
                  class="flex justify-center py-1 sm:py-3 px-3 rounded border-2 mt-5"
                >
                  <input
                    v-model="negative_prompt"
                    placeholder="Address Line 2"
                    class="flex-grow outline-none text-lg bg-transparent text-gray-700"
                    type="String"
                  />
                  <b class="text-gray-600">Address Line 2</b>
                </div>

                <div
                  class="flex justify-center py-1 sm:py-3 px-3 rounded border-2 mt-5"
                >
                  <input
                    v-model="negative_prompt"
                    placeholder="City"
                    class="flex-grow outline-none text-lg bg-transparent text-gray-700"
                    type="String"
                  />
                  <b class="text-gray-600">City</b>
                </div>

                <div
                  class="flex justify-center py-1 sm:py-3 px-3 rounded border-2 mt-5"
                >
                  <input
                    v-model="negative_prompt"
                    placeholder="State/Province"
                    class="flex-grow outline-none text-lg bg-transparent text-gray-700"
                    type="String"
                  />
                  <b class="text-gray-600">State/Province </b>
                </div>

                <div
                  class="flex justify-center py-1 sm:py-3 px-3 rounded border-2 mt-5"
                >
                  <input
                    v-model="negative_prompt"
                    placeholder="ZIP/Postal Code"
                    class="flex-grow outline-none text-lg bg-transparent text-gray-700"
                    type="String"
                  />
                  <b class="text-gray-600">ZIP/Postal Code</b>
                </div>

                <div
                  class="flex justify-center py-1 sm:py-3 px-3 rounded border-2 mt-5"
                >
                  <input
                    v-model="negative_prompt"
                    placeholder="Country"
                    class="flex-grow outline-none text-lg bg-transparent text-gray-700"
                    type="String"
                  />
                  <b class="text-gray-600">Country</b>
                </div>

                <a href="/checkout" class="flex justify-center">
                  <div
                    class="inline-block mt-16 p-px bg-gradient-to-r from-blue-700 to-red-500 rounded-2xl"
                  >
                    <div
                      class="inline-block text-lg px-2 py-1 sm:px-4 sm:py-4 border-none rounded-2xl text-gray-200 bg-gray-900 cursor-pointer hover:bg-gray-200 hover:text-gray-900"
                    >
                      CONTINUE TO SHIPPING
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <hr class="mt-16 mb-16" />
          </div>
        </div>

        <StickFooter class="mt-44" />
      </div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/Footer.vue";

import { mapGetters, mapActions } from "vuex";

// import { products, main_product, products_2 } from "../../products";

export default {
  data() {
    return {};
  },
  components: {
    ConnectWallet,
    StickFooter,
  },
  computed: mapGetters(["getMenu"]),

  methods: {},
  created() {},
};
</script>
